.App {
  text-align: center;
}

/* Buttons */
.menuButtonMargin {
  margin-left: 11px !important;
}

/* Logo */
.logoMargins {
  margin: 15px 0 15px 0;
}

/* Media */
@media only screen and (max-width: 500px) {
  .showOnDesktop{
      display:none !important;
  }
}

@media only screen and  (min-width: 501px) {
  .showOnMobile{
      display:none !important;
  }

}

/* Menu */
.textGrow {
  flex-grow: 1 !important;
}

.menuStyling {
  background-color: white !important;
  color: #000 !important;
  box-shadow: none !important;
  border-bottom: 2px solid #eee !important;
}