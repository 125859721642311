/* Buttons */
.buttonLeftMargin {
    margin-left: 6px !important;
}

.buttonTopMargin {
    margin-top: 33px !important;
}

.close {
    cursor: pointer;
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.getStartedButtonMargin {
    margin: 20px 0px 23px 0px !important
}

.tryForFreeButtonMargin {
    margin-bottom: 20px !important;
}

/* Container */

.headerContainerHeight {
    min-height: 80vh !important;
}

.containerPadding {
    margin-bottom: 40px;
}

/* Dialog */
.dialogTitlePaddingSmaller {
    display: block !important;
    padding: 15px 0px 0px 0px !important;
}

/* Footer */

.footerBackground {
    background-color: #DCDCDC;
}

/* Images */
.gif--margin {
    margin: 45px 0px 25px 0px !important;
}

/* Grid */
.centered {
    text-align: center;
}

.girdMarginTop {
    margin-top: 100px;
  }

  .rightAligned {
    text-align: right;
}

  /* Grid width */

@media only screen and (max-width: 500px) {
    .phoneTextPadding {
        padding: 0px 30px 20px 30px !important;
    }
}

@media only screen and (min-width: 501px) {
    .phoneTextPadding {
        padding: 37px 30px 20px 30px !important;
    }
}
  

/* Images */

.checkmarkMargin {
    margin-bottom: -5px !important;
    margin-right: 10px !important;
}

.howItWorksImage {
    margin: 0px 0 20px 0;
}


.imageMargin {
    margin-top: 5em !important;
}

.logoMargin {
    margin-top: 100px;
}

.logoSize {
    max-width: 80%;
    height: auto;
    width: auto\9;
}

.pricingImage {
    margin: 29px 0 20px 0;
}


/* Media */

@media only screen and (max-width: 500px) {
    .descriptionMarginResponsive {
        margin-top: 0px !important;
    }

    .descriptionMargin {
        margin-top: 0px !important;
    }
}

@media only screen and (min-width: 501px) {
    .descriptionMarginResponsive {
        margin-top: 50px !important;
    }

    .descriptionMargin {
        margin-top: 50px !important;
    }
}

@media only screen and (max-width: 500px) {
    .hiddenWhenMobile {
        display: none !important;
    }
}

@media only screen and (min-width: 501px) {
    .hiddenWhenLarge {
        display: none !important;
    }
}

@media only screen and (max-width: 500px) {
    .howItWorksGrid {
        padding: 68px 68px 15px 68px !important;
    }
    .pricingGrid {
        padding: 19px !important;
    }
}

@media only screen and (min-width: 501px) {
    .howItWorksGrid {
        padding: 32px !important;
    }
    .pricingGrid {
        padding: 18px !important;
    }
}

@media only screen and (max-width: 500px) {
    .phoneDescriptionIphoneInRow {
        font-size: 24px !important;
        color: #888;
        margin-bottom: 1.3em !important;
    }
}

@media only screen and (min-width: 501px) {
    .phoneDescriptionIphoneInRow {
        font-size: 24px !important;
        color: #888;
        margin-bottom: 1.3em !important;
    }
}

@media only screen and (max-width: 500px) {
    .sectionDescriptionGifLarge {
        margin-top: 0px !important;
    }

    .sectionDescriptionLarge {
        font-size: 34px !important;
        color: #888;
    }
}

@media only screen and (min-width: 501px) {
    .sectionDescriptionGifLarge {
        margin-top: 64px !important;
    }

    .sectionDescriptionLarge {
        font-size: 40px !important;
        color: #888;
    }
}

@media only screen and (max-width: 500px) {
    .sectionLargeHeadline {
        font-size: 34px !important;
        margin-bottom: 55px !important;
    }

    .sectionLargeHeadlineLessMargin {
        font-size: 34px !important;
        margin-bottom: 55px !important;
    }

    .sectionLargeHeadlineNoMargin {
        font-size: 34px !important;
        margin-bottom: 20px !important;
    }
}

@media only screen and (min-width: 501px) {
    .sectionLargeHeadline {
        font-size: 46px !important;
        margin-bottom: 75px !important;
    }

    .sectionLargeHeadlineLessMargin {
        font-size: 34px !important;
        margin-bottom: 40px !important;
    }

    .sectionLargeHeadlineNoMargin {
        font-size: 46px !important;
        margin-bottom: 20px !important;
    }
}

/* Paper */

.paperPadding {
    padding: 15px !important;
}

/* Responsiveness */

.responsiveStepByStep {
    width: 100%;
    max-width: 500px;
    height: auto;
}

.responsiveWalkthrough {
    width: 100%;
    max-width: 800px;
    height: auto;
}

/* Typography */

.appleFont {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu !important;
    line-height: 1.125 !important;
    font-weight: 600 !important;
    letter-spacing: .004em !important;
}

.headlineColor {
    color: #000 !important;
}

.marginPricing {
    margin-top: 15px !important;
}

.sectionDescription {
    font-size: 22px !important;
    color: #888;
    margin-bottom: 0.90em !important;
}

.sectionDescriptionMarginTop {
    font-size: 22px !important;
    color: #888;
    margin-bottom: 0.90em !important;
    margin-top: 20px !important;

}

.sectionHeaderDescription {
    font-size: 24px !important;
    line-height: 1.16667 !important;
    font-weight: 600 !important;
    letter-spacing: .009em !important;
    margin-bottom: 30px !important;
}

.sectionHeadline {
    font-size: 30px !important;
    margin-top: 40px;
}

.sectionHeadlineDialog {
    font-size: 28px !important;
}

.sectionLargeHeadlineMargins {
    margin-top: 70px !important;
}


.sloganMargin {
    margin-top: 16px !important;
}

.typographyMarginTop {
    margin-top: 40px !important;
  }